<template>
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row; font-weight: bold"
      class="py-2"
    >
      <v-flex xs12 class="text-center">
        <span class="pl-1 black--text">{{ Text }} </span>
      </v-flex>
    </v-layout>
</template>

<script>
export default {
  name: "RowHeader",
  //   mixins: [globalFunctionMixin],
  components: {
    // PopupMenu,
  },
  props: {
    Text: {
      type: String,
      default: "", //default v-data-table footer height
    },
  },
};
</script>

<style></style>
