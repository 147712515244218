<template>
  <v-layout
    row
    style="background-color: GhostWhite; flex-flow: row"
    class="py-2"
    @click="OnClick()"
  >
    <v-flex xs3 class="text-left">
      <span class="pl-1">{{ Header }}</span>
    </v-flex>
    <v-flex xs8 class="text-left">: {{ Data }} </v-flex>
    <v-flex xs1 class="text-left" v-if="isClick == true"
      ><v-icon>mdi-chevron-right</v-icon></v-flex
    >
  </v-layout>
</template>

<script>
export default {
  name: "RowData",
  //   mixins: [globalFunctionMixin],
  components: {
    // PopupMenu,
  },
  props: {
    Header: {
      type: String,
      default: "", //default v-data-table footer height
    },
    Data: {
      type: String,
      default: "", //default v-data-table footer height
    },
    isClick: {
      type: Boolean,
      default: true, //default v-data-table footer height
    },
  },
  methods: {
    OnClick() {
      this.$emit("OpenDlg");
    },
  },
};
</script>

<style></style>
