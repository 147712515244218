<template>
  <div
    class="pt-2 pb-2 my-0 px-0 mx-0"
    style="background-color: GhostWhite"
    @click="OnClick()"
  >
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row"
      class="pt-0"
    >
      <v-flex xs3 class="text-left">
        <span class="pl-1">{{ Header }}</span>
      </v-flex>
      <v-flex xs8 class="text-left">: {{ Data }}</v-flex>
      <v-flex xs1 class="text-left"><v-icon>mdi-chevron-right</v-icon></v-flex>
    </v-layout>
    <!-- <v-divider></v-divider> -->
    <!-- อธิบายสร้างรหัส -->
    <v-layout
      row
      style="background-color: GhostWhite; flex-flow: row; font-size: 3.5vw"
      class="pb-0"
    >
      <v-flex xs12 class="text-left">
        <span class="pl-4 red--text">{{ Notice }}</span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "RowData",
  //   mixins: [globalFunctionMixin],
  components: {
    // PopupMenu,
  },
  props: {
    Header: {
      type: String,
      default: "", //default v-data-table footer height
    },
    Data: {
      type: String,
      default: "", //default v-data-table footer height
    },
    Notice: {
      type: String,
      default: "", //default v-data-table footer height
    },
    isClick: {
      type: Boolean,
      default: true, //default v-data-table footer height
    },
  },
  methods: {
    OnClick() {
      this.$emit("OpenDlg");
    },
  },
};
</script>

<style></style>
