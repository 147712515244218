<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title
        >สร้างรหัสเชื่อมต่อ</v-card-title
      >
      <v-card-text>
        <!-- <v-text-field
          v-model="EditData"
          color="pink"
          :label="Label"
          ref="txtName"
          required
          dense
          outlined
          clearable
        ></v-text-field> -->
        หลังจากสร้างให้ลูกค้ากรอกรหัสภายใน 15 นาที
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">ยกเลิก</v-btn>
        <v-btn text @click="edit()">ตกลง</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OpenCloseDlg",
  components: {},

  props: {
    value: Boolean,
    OldData: String,
    Header: String,
    Label: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value: function (value) {
      if (value) this.EditData = "";
    },

    // show(visible) {
    //   if (visible) {
    //     this.EditData = "";
    //     // this.$refs.txtCusName.focus();
    //     // Here you would put something to happen when dialog opens up
    //     // this.$refs.pincode.values = ["", "", "", "", ""];
    //     console.log("Dialog was opened!");
    //   } else {
    //     console.log("Dialog was closed!");
    //   }
    // },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    edit() {
      this.$emit("NewData", this.value);
      this.$emit("input", false);
    },
  },
  data: () => ({
    EditData: "",
    // dialog: false,
  }),
};
</script>

<style></style>
