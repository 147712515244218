<template>
  <v-container fluid grid-list-xs fill-height class="container">
    <v-layout row wrap class="layout header_bg" fluid>
      <v-flex xs12 class="row_title">
        <v-layout
          row
          style="
            flex-flow: row;
            background-color: GhostWhite;
            padding-top: 10;
            font-weight: bold;
          "
          class="grey lighten-5"
        >
          <v-flex xs4 class="text-left ml-1 pl-0 mb-2 mt-2">
            <!-- style="text-decoration: underline" -->
            <v-btn icon @click="OnBackToCustomer()">
              <v-icon color="pink lighten-1" large
                >mdi-arrow-left-bold-circle</v-icon
              >
            </v-btn>
            ย้อนกลับ
          </v-flex>
          <v-flex xs8 class="text-right mr-1 mt-3 pink--text text--darken-1">
            ข้อมูลลูกค้า</v-flex
          >
        </v-layout>
        <v-divider class="pink lighten-1"></v-divider>
      </v-flex>
      <v-flex xs12 class="row_content">
        <RowHeader Text="ข้อมูลลูกค้า" />
        <v-divider></v-divider>
        <RowData Header="ID" :Data="CusID" :isClick="false" />
        <v-divider></v-divider>
        <RowData
          Header="ชื่อ"
          :Data="CusName"
          @OpenDlg="ShowEditNameDlg = true"
        />
        <v-divider></v-divider>
        <RowData
          Header="เครดิต"
          :Data="CreditText()"
          @OpenDlg="ShowEditCreditDlg = true"
        />
        <v-divider></v-divider>
        <RowData
          Header="สถานะ"
          :Data="StatusText()"
          @OpenDlg="ShowEditStatusDlg = true"
        />
        <v-divider></v-divider>
        <!-- HEADER ข้อมูลลูกค้า -->
        <RowHeader class="mt-1" Text="การเชื่อมต่อ" />
        <v-divider></v-divider>
        <!-- สร้างรหัส -->
        <RowData2Line
          Header="รหัสเชื่อมต่อ"
          :Data="CodeText()"
          Notice="** หลังจากสร้างรหัส ลูกค้าต้องกรอกรหัสภายใน 15 นาที **"
          @OpenDlg="ShowCodeDlg = true"
        />
        <v-divider></v-divider>
        <RowData2Line
          Header="PIN ลูกค้า"
          :Data="PinText()"
          Notice="** ตรงนี้เป็น PIN 4 ตัว ลูกค้าสร้างเอง **"
          @OpenDlg="ShowEditPinDlg = true"
        />
        <v-divider></v-divider>
        <!-- <EditCusNameDlg v-model="ShowEditNameDlg" :OldData="CusName" @NewData="OnEditCusName" /> -->

        <EditTextDlg
          v-model="ShowEditNameDlg"
          :OldData="CusName"
          Header="ชื่อลูกค้า"
          @NewData="OnEditCusName"
        />
        <EditCreditDlg
          v-model="ShowEditCreditDlg"
          :OldData="Credit"
          @NewData="OnEditCredit"
        />

        <EditStatusDlg
          v-model="ShowEditStatusDlg"
          :OldStatus="parseInt(Status)"
          @NewStatus="OnEditStatus"
        />

        <EditTextDlg
          v-model="ShowEditPinDlg"
          :OldData="PIN"
          Header="PIN ลูกค้า"
          @NewData="OnEditPIN"
        />
        <CreateCodeDlg
          v-model="ShowCodeDlg"
          :OldData="PIN"
          @NewData="OnGenCode"
        />
      </v-flex>
    </v-layout>
  </v-container>

  <!-- <div>ตาราง</div> -->

  <!-- <div>ยอดลอย</div> -->
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

// import EditCusNameDlg from "@/components/Customer/Customer/EditCusNameDlg";
import EditStatusDlg from "@/components/Customer/Customer/EditStatusDlg";

import EditTextDlg from "@/components/Customer/Customer/EditTextDlg";
import EditCreditDlg from "@/components/Customer/Customer/EditCreditDlg";

import CreateCodeDlg from "@/components/Customer/Customer/CreateCodeDlg";

import RowHeader from "@/components/Customer/Customer/RowHeader";
import RowData from "@/components/Customer/Customer/RowData";
import RowData2Line from "@/components/Customer/Customer/RowData2Line";

export default {
  name: "Pages_Main",
  mixins: [globalFunctionMixin],
  components: {
    // EditCusNameDlg,
    EditStatusDlg,
    EditTextDlg,
    EditCreditDlg,
    CreateCodeDlg,
    RowHeader,
    RowData,
    RowData2Line,
    //component name,
  },
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    // console.log('CusID');
    // console.log(this.$route.query.CusID);
    // console.log(typeof this.$route.query.CusID);
    if (typeof this.$route.query.CusID == "undefined") {
      console.log("undefined");
    } else {
      this.CusID = this.$route.query.CusID;
    }

    this.GetData();

    setTimeout(() => {
      // this.updateDataTableHeight();
    }, 1000);
  },
  computed: {
    // PageSendCount() {
    //   //   console.log("update lines_data");
    //   return this.$store.getters["pages/PageSendCount"];
    // },
    // PriceSumAll() {
    //   return this.$store.getters["pages/PriceSumAll"];
    // },
    // PriceLoyAll() {
    //   return this.$store.getters["pages/PriceLoyAll"];
    // },
  },
  methods: {
    async GetData() {
      let parameter = {
        to: "Cus",
        CusID: this.CusID,
        method: "get_data",
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("pages/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
          console.log(response);
          this.SetCusData(response.CusData);
        },
        (error) => {
          //   switch (error) {
          //     case "PIN_INCORRECT":
          //       this.$refs.pincodeInput.resetPincode();
          //       this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
          //       break;
          //     default:
          //       alert(error);
          //       break;
          //   }
        }
      );
    },
    OnEditStatus(p_value) {
      let Status = p_value == true ? 0 : 1;
      if (Status == this.Status) return;
      this.EditData("Status", Status);
    },
    OnEditCusName(p_value) {
      if (this.CusName == p_value) return;
      this.EditData("CusName", p_value);
    },
    OnEditCredit(p_value) {
      if (this.Credit == p_value) return;
      this.EditData("Credit", p_value);
    },
    OnEditPIN(p_value) {
      if (this.PIN == p_value) return;
      if (p_value.length != 4) {
        this.$alert("รหัส PIN ต้องเป็นตัวเลข 4 ตัว", "ผิดพลาด", "error").then(
          () => {}
        );
        return;
      }

      let isnum = /^\d+$/.test(p_value);
      if (!isnum) {
        this.$alert("รหัส PIN ต้องเป็นตัวเลขเท่านั้น", "ผิดพลาด", "error").then(
          () => {}
        );
        return;
      }
      this.EditData("PIN", p_value);
    },
    OnGenCode() {
      console.log("GEN CODE");
      this.GenCode();
    },
    async EditData(p_Feild, p_Value) {
      let parameter = {
        to: "Cus",
        CusID: this.CusID,
        method: "edit",
      };
      parameter[p_Feild] = p_Value;

      await this.$store.dispatch("pages/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
          console.log(response);
          this.SetCusData(response.CusData);
        },
        (error) => {
          //   switch (error) {
          //     case "PIN_INCORRECT":
          //       this.$refs.pincodeInput.resetPincode();
          //       this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
          //       break;
          //     default:
          //       alert(error);
          //       break;
          //   }
        }
      );
    },

    async GenCode() {
      let parameter = {
        to: "Cus",
        CusID: this.CusID,
        method: "gen_code",
      };

      await this.$store.dispatch("pages/TransferData", parameter).then(
        (response) => {
          console.log(response);
          this.Code = response.VERIFY_CODE['VarifyCode'];
          this.CodeExpire = response.VERIFY_CODE['VExpire'];
        //   this.SetCusData(response.CusData);
        },
        (error) => {
          //   switch (error) {
          //     case "PIN_INCORRECT":
          //       this.$refs.pincodeInput.resetPincode();
          //       this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
          //       break;
          //     default:
          //       alert(error);
          //       break;
          //   }
        }
      );
    },

    SetCusData(p_CusData) {
      this.CusName = p_CusData.CusName;
      this.Credit = parseInt(p_CusData.Credit);
      this.Code = p_CusData.Code;
      this.CodeExpire = p_CusData.CodeExpire;
      this.LoginDT = p_CusData.LoginDT;
      this.PIN = p_CusData.PIN;
      this.Status = parseInt(p_CusData.Status);
    },
    CreditText() {
      if (this.Credit == -1) return "ไม่จำกัด";
      return String(this.Credit);
    },
    StatusText() {
      if (this.Status == 0) return "ปิดไม่ให้แทง";
      return "ปกติ";
    },
    PinText() {
      if (this.PIN == "") return "ยังไม่ได้สร้าง";
      return this.PIN;
    },
    CodeText() {
      if (this.Code == "") return "ยังไม่ได้สร้าง";
      return this.Code;
    },
    async doAddPage() {
      if (this.Sending == false) return;
      if (this.PeriodIsOpen == 0) return;
      let parameter = {
        to: "Pages",
        method: "add_new",
        CusID: this.CusID,
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("pages/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
          console.log(response);
          this.Sending = false;

          this.$router.push({
            path: "Page",
            query: {
              PageNumber: response.PageNumber,
              CusID: this.CusID,
            },
          });
        },
        (error) => {
          this.Sending = false;
        }
      );
    },
    OnBackToCustomer() {
      this.$router.push({
        path: "Customers",
        query: {
          Tab: 2,
          CusID: this.CusID,
        },
      });
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),
    CusID: "001",

    CusName: "",
    Credit: 0,
    Code: "",
    ExpireCode: "0000-00-00 00:00:00",
    PIN: "",
    Status: 0,
    LoginDT: "0000-00-00 00:00:00",

    ShowEditNameDlg: false,
    ShowEditCreditDlg: false,
    ShowEditStatusDlg: false,
    ShowEditPinDlg: false,
    ShowCodeDlg: false,

    Sending: false,
  }),
};
</script>

<style scoped>
.container {
  background-color: red;
  flex-grow: 1;
  flex-shrink: 0;
}
.layout {
  flex-flow: column;
  padding: 2px;
}
.row_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1;
}
.row_content {
  background-color: rgb(197, 197, 197);
  flex: 3 0px;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
}

.header_bg {
  background-color: whitesmoke;
  color: black;
  font-size: 4.4vw;
  padding-top: 0;
}

.line {
  height: 2px;
  width: 100%;
  background-color: black;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable {
  cursor: pointer;
}
</style>
