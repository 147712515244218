<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title
        >{{ Header }} จาก
        <strong class="indigo--text ml-2 mr-2">{{ CreditText() }}</strong>
        เป็น</v-card-title
      >
      <v-card-text>
        <v-col cols="12">
          <v-switch
            v-model="IsCredit"
            class="mt-0"
            color="primary"
            label="จำกัดวงเงิน"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="EditData"
            color="pink"
            label="วงเงินสูงสุดต่องวด"
            type="Number"
            outlined
            clearable
            dense
          ></v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">ยกเลิก</v-btn>
        <v-btn text @click="edit()">แก้ไข</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OpenCloseDlg",
  components: {},

  props: {
    value: Boolean,
    OldData: Number,
    Header: String,
    Label: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value: function (value) {
      if (value){
        this.EditData = this.OldData;
      }
    },

    // show(visible) {
    //   if (visible) {
    //     this.EditData = "";
    //     // this.$refs.txtCusName.focus();
    //     // Here you would put something to happen when dialog opens up
    //     // this.$refs.pincode.values = ["", "", "", "", ""];
    //     console.log("Dialog was opened!");
    //   } else {
    //     console.log("Dialog was closed!");
    //   }
    // },
  },
  methods: {
    CreditText(){
        if(this.OldData == -1){
            this.IsCredit = false;
            return "ไม่จำกัดวงเงิน";
        }
        this.IsCredit = true;
        return this.OldData;
    },
    // ToggleValue(){
    //     if(this.OldData == -1){
    //         IsCredit return false;
    //     }
    //     return true;
    // },
    close() {
      this.$emit("input", false);
    },
    edit() {
      this.$emit("NewData", this.EditData);
      this.$emit("input", false);
    },
  },
  data: () => ({
    EditData: 0,
    IsCredit: false,
    // dialog: false,
  }),
};
</script>

<style></style>
